import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './dashboard.css'

interface Tag {
    id: number;
    name: string;
    description: string;
}

const apiUrl = process.env.REACT_APP_API_URL;

const Dashboard = () => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [tags, setTags] = useState('');
    const [country, setCountry] = useState('Stanford');
    const [images, setImages] = useState<FileList | null>(null);
    const navigate = useNavigate();
    const [fetchedTags, setFetchedTags] = useState<Tag[]>([]);
    const [tagNames, setTagNames] = useState('');

    const [buttonText, setButtonText] = useState('Create Post');

    const handleClick = () => {
        setButtonText('Sending post...');
    };

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/tags`);
                const tags = response.data.tags;
                const tagNames = tags.map((tag : Tag) => tag.name).join(', ');
                setFetchedTags(tags);
                setTagNames(tagNames);
            } catch (error) {
                console.error('Error fetching tags:', error);
            }
        };

        fetchTags();
    }, []);


    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setImages(event.target.files);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const userConfirmed = window.confirm("Envoyer ce poste ?");

        if (!userConfirmed) {
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('text', text);
        formData.append('country', country);

        if (tags) {
            const tagsList: string[] = tags.split(',').map((item) => item.trim());
            for (let i = 0; i < tagsList.length; i++) {
                formData.append('tags', tagsList[i]);
            }
        }

        if (images) {
            for (let i = 0; i < images.length; i++) {
                formData.append('images', images[i]);
            }
        }


        const token: string = Cookies.get('token')!

        const axiosConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data', // You may need to set the appropriate content type
            },
        };

        try {
            await axios.post(`${apiUrl}/api/newpost`, formData, axiosConfig);
            setButtonText("Post created!")
            navigate('/');
        } catch (error) {
            console.error('Error creating a new post:', error);
            alert("erreur : " + error);
        }
    };


    return (
        <div className={"dashboard"}>
            <h2>Create a New Post</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Title: </label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Text: </label>
                    <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Tags: </label>
                    <input
                        type="text"
                        placeholder="Add tags (comma-separated)"
                        onChange={(e) => setTags(e.target.value)}
                    />
                    <span><i>{tagNames}</i></span>
                </div>
                <div>
                    <label>Images: </label>
                    <input type="file" multiple accept="image/*" onChange={handleImageChange} />
                </div>
                <div>
                    <button type="submit" onClick={handleClick}>
                        {buttonText}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Dashboard;
